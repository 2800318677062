import React from "react";
import AlertMessage from "./AlertMessage";

const Home = () => {
  return (
    <>
      <div className="container h-screen flex flex-col justify-center">
        {/* <AlertMessage message=" Zooimpex has ceased trading since January of this year. Unfortunately, we have recently discovered that a scammer has been using our name to defraud clients." /> */}
        <div className="flex flex-row">
          <div className="lg:basis-1/2">
            <div className="text-black font-black text-4xl lg:text-6xl">
              Under
              <br />
              Construction
            </div>

            <div className="text-gray-600 font-normal pt-4 text-base lg:text-lg">
              Welcome to Zooimpex - Your Expert in Exotic Pets!
            </div>

            <div className="text-gray-600 font-normal py-2 text-base lg:text-lg">
              Our website is currently under construction to bring you a more
              user-friendly experience. However, we are excited to share our
              pricing list with you!
            </div>

            <div className="text-gray-600 font-normal py-2 text-base lg:text-lg">
              Please find our pricing list below:
            </div>

            <button
              className="bg-teal-600 hover:bg-teal-800 text-white font-bold text-base py-3 my-4 px-5 rounded"
              onClick={() => {
                window.location.href = "/prices";
              }}
            >
              Pricing
            </button>

            <div className="text-gray-600 font-normal py-2 text-base lg:text-lg">
              For any inquiries or to place an order, please reach out to us at:
              <ul className="list-disc pl-5 pt-2">
                <li>Email: zooimpex@protonmail.com</li>
                <li>WhatsApp: +46 737278769</li>
              </ul>
              Stay tuned for more updates on our website!
            </div>
          </div>

          <div className="basis-0 lg:basis-1/2">
            <img
              className="pointer-events-none"
              src="/underconstruction.png"
              alt="banner"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
