import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import Prices from './components/Prices';


function App() {
  return (
    <div className="bg-orange-50">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/prices" element={<Prices />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;