import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <section className="text-white bg-teal-700 w-full">
      <footer className="flex flex-col items-center font-normal py-6">
        <div className="flex flex-row w-full justify-evenly">
          <div>
            <div className="flex flex-row items-center text-base lg:text-lg">
              <FontAwesomeIcon icon={faWhatsapp} className="h-5" />
              <a className="text-white px-2" href="tel:+46 737278769">
                +46 737278769
              </a>
            </div>

            <div className="flex flex-row items-center text-base lg:text-lg">
              <FontAwesomeIcon icon={faEnvelope} />
              <a
                className="text-white px-2"
                href="mailto:zooimpex@protonmail.com"
              >
                zooimpex@protonmail.com
              </a>
            </div>
          </div>
        </div>

        <div className="text-center pt-4 text-sm lg:text-base">
          © 2022 Zooimpex. All rights reserved.
        </div>
      </footer>
    </section>
  );
};

export default Footer;
