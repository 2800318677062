import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PDF from "../assets/prices.pdf";
import AlertMessage from "./AlertMessage";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const Prices = () => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  var pageWidth = 296;
  if (window.innerWidth > 500) {
    pageWidth = 450;
  }
  if (window.innerWidth > 640) {
    pageWidth = 600;
  }
  if (window.innerWidth > 1024) {
    pageWidth = 980;
  }

  return (
    <div className="w-screen flex flex-col justify-center items-center">
      <a
        href={PDF}
        target="_blank"
        rel="noreferrer"
        download="Zooimpex_prices_2024.pdf"
        className="mt-32 font-black md:text-lg lg:text-xl"
      >
        {" "}
        Download Pdf{" "}
      </a>

      {/* <div className="pt-10 px-2">
        <AlertMessage message=" Zooimpex has ceased trading since January of this year. Unfortunately, we have recently discovered that a scammer has been using our name to defraud clients." />
      </div> */}

      <Document
        file={PDF}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
        className="my-20"
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            width={pageWidth}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>

      <a
        href={PDF}
        target="_blank"
        rel="noreferrer"
        download="ZooimpexPrices2022.pdf"
        className="mb-32 font-black md:text-lg lg:text-xl"
      >
        {" "}
        Download Pdf{" "}
      </a>
    </div>
  );
};

export default Prices;
