import React from 'react'
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <div>
            <nav className="navbar fixed-top bg-orange-50 shadow-sm">

                <div className="container-fluid container">

                    <NavLink to="/">
                        <img className="pointer-events-none h-12 lg:h-14 py-1" src="/logo.png" alt="logo" />
                    </NavLink>

                    {/* <NavLink to="/">
                        <div>Home</div>
                    </NavLink>
                    <NavLink to="/">
                        <div>About us</div>
                    </NavLink>
                    <NavLink to="/">
                        <div>Contact us</div>
                    </NavLink> */}

                </div>

            </nav>
        </div>
    )
}

export default Navbar;